import { RequestControlService } from "@core/services/request-control.service";

import { EMPTY, Observable } from "rxjs";

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private requestControlService: RequestControlService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.requestControlService.blockPostRequests && req.method === "POST") {
      return EMPTY;
    }

    return next.handle(req);
  }
}
