import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RequestControlService {
  blockPostRequests = false;

  setBlockPostRequests(value: boolean): void {
    this.blockPostRequests = value;
  }
}
